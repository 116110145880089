import Head from "next/head";
import { getRobotsMetaContent } from "utils/utils";

const structuredOrgData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "CoLearn",
  url: process.env.NEXT_PUBLIC_SITE_URL,
  logo: `${process.env.NEXT_PUBLIC_SITE_URL}/assets/colearn_logo.png`,
  sameAs: [
    "https://www.facebook.com/colearn.id",
    "https://www.instagram.com/colearn.id/",
    "https://www.youtube.com/c/colearn",
    "https://www.linkedin.com/company/colearnid/",
    "https://twitter.com/colearn_id",
    "https://www.tiktok.com/@colearn.id"
  ]
};

const structuredWebsiteData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  url: `${process.env.NEXT_PUBLIC_SITE_URL}`,
  potentialAction: {
    "@type": "SearchAction",
    target: {
      "@type": "EntryPoint",
      urlTemplate: `${process.env.NEXT_PUBLIC_SITE_URL}/tanya/search?question={search_term_string}`
    },
    "query-input": "required name=search_term_string"
  }
};

const HomeSEO: React.FC = () => (
  <Head>
    <title>
      Aplikasi belajar online, bantu kamu belajar dari mana aja - CoLearn
    </title>
    <meta
      name="title"
      content="Aplikasi belajar online, bantu kamu belajar dari mana aja - CoLearn"
    />
    <meta
      name="description"
      content="Colearn adalah aplikasi belajar online matematika, fisika dan kimia. Bimbel online interaktif dan aplikasi foto soal dengan video pembahasan untuk SD, SMP SMA."
    />
    <meta
      name="keywords"
      content="Cara jawab,Rumus soal,Cari cara,Cara cari jawaban,Cari rumus,Video pelajaran,Cara ngitung,Jawaban lengkap,Jawaban lengkap tema,Cara belajar matematika,pembelajaran jarak jauh,anak susah belajar,Materi belajar,Kumpulan soal,kumpulan soal jawaban,Topik kelas,les privat,Masalah matematika,Bimbel,Bimbel online,Bimbel terbaik,Kelas online,PJJ,Motivasi belajar,Belajar online di rumah,Guru online,Belajar matematika,Matematika online"
    />
    <meta
      name="zoom-domain-verification"
      content="ZOOM_verify_NHv_PVShSkSvSmh0_zFYpw"
    />
    <link rel="canonical" href={process.env.NEXT_PUBLIC_SITE_URL} />
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredOrgData) }}
    />
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredWebsiteData)
      }}
    />
    <meta
      property="og:title"
      content="Aplikasi belajar online, bantu kamu belajar dari mana aja - CoLearn"
    />
    <meta
      property="og:description"
      content="Aplikasi belajar online yang bantu belajar Matematika, Fisika, Kimia jadi lebih cepat dimengerti. Ada beragam fitur aplikasi mulai dari bantuan mengerjakan PR dengan foto soal sampai bimbel untuk SD, SMP, SMA."
    />
    <meta
      property="og:image"
      content={`${process.env.NEXT_PUBLIC_SITE_URL}/assets/share-og.jpg`}
    />
    <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="robots" content={getRobotsMetaContent()} />
  </Head>
);

export { HomeSEO };

// dummy commit
