import React from "react";
import { StyledArrow } from "./icons.styles";
import { IIcon } from "./icons.types";

const Arrow: React.FC<IIcon> = ({
  width = 16,
  height = 16,
  color = "currentColor",
  direction = "left"
}) => (
  <StyledArrow
    direction={direction}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.31992 9.16013H10.0799C10.1465 9.15973 10.2117 9.1793 10.2671 9.21632C10.3225 9.25333 10.3656 9.3061 10.3907 9.36781C10.4158 9.42951 10.4218 9.49733 10.408 9.56251C10.3942 9.62769 10.3612 9.68723 10.3133 9.73346L8.89992 11.1268C8.80538 11.2193 8.73027 11.3298 8.67899 11.4517C8.6277 11.5736 8.60129 11.7045 8.60129 11.8368C8.60129 11.9691 8.6277 12.1 8.67899 12.2219C8.73027 12.3438 8.80538 12.4543 8.89992 12.5468L9.20658 12.8535C9.39545 13.0356 9.64758 13.1373 9.90992 13.1373C10.1723 13.1373 10.4244 13.0356 10.6133 12.8535L13.8133 9.70013C14.2838 9.24775 14.585 8.64772 14.6666 8.00013C14.5871 7.35664 14.2908 6.75941 13.8266 6.3068L10.6266 3.15346C10.4377 2.97137 10.1856 2.86963 9.92325 2.86963C9.6609 2.86963 9.40878 2.97137 9.21992 3.15346L8.91325 3.45346C8.81851 3.54654 8.74326 3.65756 8.69189 3.78003C8.64053 3.90251 8.61407 4.03399 8.61407 4.1668C8.61407 4.29961 8.64053 4.43109 8.69189 4.55356C8.74326 4.67604 8.81851 4.78705 8.91325 4.88013L10.3266 6.27346C10.3757 6.31871 10.4097 6.37791 10.4242 6.4431C10.4386 6.50828 10.4327 6.57633 10.4073 6.63806C10.3819 6.6998 10.3381 6.75226 10.282 6.78839C10.2258 6.82452 10.16 6.84258 10.0933 6.84013H2.33325C2.06804 6.84013 1.81369 6.94549 1.62615 7.13302C1.43861 7.32056 1.33325 7.57491 1.33325 7.84013V8.16013C1.33323 8.42305 1.43676 8.67541 1.62142 8.86257C1.80608 9.04972 2.05702 9.15662 2.31992 9.16013Z"
      fill={color}
    />
  </StyledArrow>
);

export { Arrow };
